import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Goodbye} from "./Goodbye";


const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={<Goodbye/>}/>
            </Routes>
        </BrowserRouter>
    );
};

export default App;
