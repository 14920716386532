import React from 'react';

export interface GoodbyeProps {
}

const Goodbye: React.FC<GoodbyeProps> = (props) => {
    return (
        <div
            className={
                "text-center align-items-center d-flex justify-content-center fh flex-column p-4"
            }
        >
            <div className={"flex-fill"}/>
            <div>
                Recent changes to Twitter mean that Chirpty can't continue.
                <br />
                <br />
                Follow us <a href={"https://twitter.com/chirpty_team"}>@Chirpty_Team</a> to learn about our next exciting projects.
                <br />
                <br />
                Thank you for the great adventure.
                <br />
                See you next time.
                <br />
                <br />
                ♥
            </div>
            <div className={"flex-fill"}/>
        </div>
    );
}

export {Goodbye};